import { motion } from "framer-motion";

import "./Partner.css";
import Card from "../../Card/Card";
import { useNavigate } from "react-router-dom";

export const PROGRAMS = [
  {
    _id: "6",
    short_des:
      "Dirghayu Nepal, in collaboration with the Government of Nepal, KOICA, and UNDP, is proud to launch the Skill Development ...",
    long_des:
      "Dirghayu Nepal, in collaboration with the Government of Nepal, KOICA, and UNDP, is proud to launch the Skill Development & Technical Support for Community-Level Recycling & Upcycling (RU) in Pokhara, South Cluster Area. This initiative, active since December 2024, aims to foster sustainable green jobs through recycling and upcycling efforts across Wards 7, 10, 11, 12, 13, 14, 15, 17, and 21. With a strong focus on People, Peace, Planet, Prosperity, and Partnership, the project is designed to empower local communities by equipping them with essential skills in waste management, recycling techniques, and upcycling innovation. Through comprehensive technical training programs, hands-on workshops, and community engagement initiatives, the project will contribute to environmental sustainability while generating employment opportunities for marginalized groups. By integrating modern recycling practices with community-driven upcycling solutions, this initiative aligns with global sustainability goals and Nepal’s commitment to green economic growth. The project also aims to raise awareness about waste reduction, promote circular economy practices, and strengthen Pokhara’s role as a leading city in sustainable development. Together, we are building a greener, more resilient future for Pokhara! 🌍♻️",
    overview:
      " Dirghayu Nepal, in collaboration with the Government of Nepal, KOICA, and UNDP, has launched the Skill Development & Technical Support for Community-Level Recycling & Upcycling (RU) in Pokhara, South Cluster Area to promote sustainable green jobs through recycling and upcycling. Active since December 2024, this initiative focuses on empowering local communities across Wards 7, 10, 11, 12, 13, 14, 15, 17, and 21 by providing technical training, hands-on workshops, and awareness programs on waste management and circular economy practices. By fostering innovation in upcycling and creating employment opportunities, the project aligns with global sustainability goals and Nepal’s green economic vision, ensuring a cleaner and more resilient future for Pokhara.",

    images: ["gcru/1.png"],
    title: "Skill Dev. & Technical Support",
  },
  {
    _id: "0",
    short_des:
      "Dirghayu Nepal, in collaboration with esteemed partners, is proud to present the CS4ALL initiative, a transformative project ...",
    long_des:
      "Dirghayu Nepal, in collaboration with esteemed partners, is proud to present the CS4ALL initiative, a transformative project across India, Nepal, and Indonesia aimed at enhancing cybersecurity resilience amidst escalating cyber threats. This ambitious program, funded by Erasmus+ under the CBHE cluster, equips teachers, graduates, and civil society with essential tools, knowledge, and skills in cybersecurity to bolster IT defenses in the digital era. As these nations transition to digital economies, the initiative focuses on developing a modular cybersecurity curriculum (comprising four courses of 3 ECTS each) for academic faculty and students. Additionally, nine cybersecurity classrooms will be established or modernized across participating higher education institutions (HEIs). Emphasizing life-long learning, CS4ALL will also introduce micro-credentials for adults and professionals, ensuring continuous skill development in the cybersecurity domain. Formative workshops and awareness campaigns, conducted in collaboration with NGOs, will further educate civil society on cyber threats and protective measures.",
    overview:
      " The kickoff meeting of the Erasmus+ supported Cybersecurity for All (CS4ALL) project took place from September 21-24, 2023, at Pokhara University, Nepal, with active participation from Dirghayu Nepal and representatives from 11 partner institutions, discussing progress, planning, and establishing revised timelines for high-priority activities. The event also fostered socialization and cultural exchange, marking a significant milestone for the project's collaborative effort to enhance cybersecurity resilience across India, Nepal, and Indonesia.",
    long_des1:
      " The project is coordinated by Lovely Professional University, India, with key partners including Edulab Educational Exchange Private Limited, Lokmanya Tilak Jankalyan Shikshan Sanstha Nagpur, Gujarat University, EdEx - Educational Excellence Corporation Limited (Cyprus), Universitat Politecnica de Valencia (Spain), Visoko Uciliste Algebra (Croatia), Pokhara University (Nepal), Dirghayu Nepal, ActionAid Nepal, Institut Pertanian Bogor (Indonesia), Yayasan Pendidikan Jaya (Indonesia), and Far-Western University AIESEC Indonesia. Dirghayu Nepal, led by President Mr. Sundar Adhikari who also serves as the project manager for this  project is leading from the side of Dirghayu Nepal. Structured into seven comprehensive work packages (WPs), the project includes collecting best practices in cybersecurity (WP1), modernizing IT classrooms (WP2), designing and developing modular courses (WP3), piloting these courses (WP4), creating micro-credentials for IT professionals and adult learners (WP5), executing dissemination activities (WP6), and ensuring effective project management and coordination (WP7). Expected outcomes include retraining 32 teachers, integrating five new or updated courses into the curriculum of eight HEIs, and providing life-long learning courses to 240 adult learners and professionals. Additionally, awareness campaigns will reach 1,200 individuals within local communities, aiming to combat cybercrime through informative videos and other media. This initiative directly involves 32 teachers, 160 students, 40 SMEs and startups, 240 adult learners, and 1,200 community members, fostering a robust cybersecurity framework across the participating countries.",
    images: [
      "cs/1.jpg",
      "cs/2.jpg",
      "cs/3.jpg",
      "cs/4.jpg",
      "cs/5.jpg",
      "cs/6.jpg",
      "cs/7.jpg",
      "cs/8.jpg",
      "cs/9.jpg",
      "cs/10.jpg",
      "cs/11.jpg",
      "cs/12.jpg",
      "cs/13.jpg",
      "cs/14.jpg",
      "cs/15.jpg",
      "cs/16.jpg",
      "cs/17.jpg",
      "cs/18.jpg",
    ],
    title: "Cybersecurity for all",
  },
  {
    _id: "1",
    short_des:
      "At Dirghayu Nepal, we believe in the transformative power of education, especially in rural communities. ...",
    long_des:
      "At Dirghayu Nepal, we believe in the transformative power of education, especially in rural communities. Through our program initiatives at Manaslu Elementary School, Bahkot, and Nawa Jyoti Elementary School, Dandathok, Gorkha, we aim to strengthen educational opportunities for students and create better learning environments.",
    overview:
      "Dirghayu Nepal, with the generous support of local donors, Korean donors, and the Rotary Club, contributed to the capacity strengthening of Manaslu Elementary School and Nawa Jyoti Elementary School. This initiative focused on providing essential resources and support to improve the educational experiences of students in these rural areas.",
    impact:
      "The collaborative efforts of local donors, Korean donors, the Rotary Club, and Dirghayu Nepal have had a profound impact on the educational landscape of Manaslu Elementary School and Nawa Jyoti Elementary School. Together, we are creating better learning opportunities for students and empowering them to realize their full potential.",
    key_highlights: [
      {
        topic: "Resource Distribution",
        description:
          "Through the collective efforts of local donors, Korean donors, and the Rotary Club, Dirghayu Nepal distributed stationary items, school uniforms, books for the library, and other teaching-learning resources to Manaslu Elementary School in Bahkot and Nawa Jyoti Elementary School in Dandathok.",
      },
      {
        topic: "Empowering Students",
        description:
          "The support from our donors ensures that students have access to essential resources, such as school uniforms and stationary items, removing barriers to education and empowering students to pursue their academic goals without limitations.",
      },
      {
        topic: "Enhancing Learning",
        description:
          "The contributions from local donors, Korean donors, and the Rotary Club have enriched the educational experience for students and teachers alike. By providing books for the library and other teaching-learning resources, we are fostering a culture of reading, learning, and exploration within the school community.",
      },
    ],
    images: [
      "rural_schools/1.jpg",
      "rural_schools/2.jpg",
      "rural_schools/3.jpg",
      "rural_schools/4.jpg",
      "rural_schools/5.jpg",
      "rural_schools/6.jpg",
      "rural_schools/7.jpg",
      "rural_schools/8.jpg",
      "rural_schools/9.jpg",
      "rural_schools/10.jpg",
      "rural_schools/11.jpg",
      "rural_schools/12.jpg",
      "rural_schools/13.jpg",
      "rural_schools/14.jpg",
      "rural_schools/15.jpg",
      "rural_schools/16.jpg",
    ],
    title: "Rural primary schools",
  },
  {
    _id: "2",
    title: "Masonry Training",
    short_des:
      "Dirghayu Nepal, in collaboration with the Builder’s Association Dulegaunda Khairenitar-Chapter, Tanahun, and ...",
    long_des:
      "Dirghayu Nepal, in collaboration with the Builder’s Association Dulegaunda Khairenitar-Chapter, Tanahun, and with special support from the Suklagandaki Metropolitan City Office, initiated a transformative Skills Development Program aimed at empowering individuals in Tanahun.",
    overview:
      "This three-day Masonry Training event was designed to equip participants with essential construction skills. Participants, regardless of their prior experience in construction, were welcomed to join the program and undergo intensive training to develop the skills required for various construction works.",
    impact:
      "The Skills Development Program in Tanahun has the potential to create a lasting impact on the lives of participants and the broader community. By providing access to skill-building opportunities, we are empowering individuals to take control of their futures and pursue meaningful employment opportunities in the construction sector.",
    key_highlights: [
      {
        topic: "Collaborative Efforts",
        description:
          "The program was made possible through the collaborative efforts of Dirghayu Nepal, the Builder’s Association Dulegaunda Khairenitar-Chapter, Tanahun, and the Suklagandaki Metropolitan City Office. Together, we worked towards creating opportunities for skill development and economic empowerment in the region.",
      },
      {
        topic: "Empowering Participants",
        description:
          "Participants, many of whom were previously unemployed or lacked construction skills, had the opportunity to enhance their capabilities and gain valuable skills through hands-on training sessions. The program aimed to empower individuals by providing them with the tools they need to secure employment in the construction industry.",
      },
      {
        topic: "Uplifting Economic Circumstances",
        description:
          "Dirghayu Nepal believes that acquiring new skills can be a pathway to economic upliftment. By equipping participants with valuable construction skills, we aim to enhance their employability and contribute to improving their economic circumstances.",
      },
    ],
    images: [
      "masonry/1.jpg",
      "masonry/2.jpg",
      "masonry/3.jpg",
      "masonry/4.jpg",
      "masonry/5.jpg",
      "masonry/6.jpg",
      "masonry/7.jpg",
    ],
  },
  {
    _id: "3",
    title: "Mental Health Awareness",
    short_des:
      "Dirghayu Nepal, in coordination with the Psychiatrist's Association of Nepal (PAN), undertook ...",
    long_des:
      "Dirghayu Nepal, in coordination with the Psychiatrist's Association of Nepal (PAN), undertook a significant initiative aimed at promoting health awareness and providing vital healthcare services to underserved communities. The Free Health Camp and Psycho-social Counselling Program held at Firfirey-7, Kutkutey, in Tanahun District, exemplified our commitment to improving the well-being of individuals in remote areas.",
    overview:
      "The program encompassed a dual approach to address both physical and mental health needs within the community. Through the collaboration with PAN, we aimed to not only offer medical services but also provide psycho-social counselling to address mental health concerns.",
    impact:
      "The Promoting Health Awareness Program had a profound impact on the community, addressing both physical and mental health needs while providing essential support to improve overall well-being. By offering comprehensive healthcare services and psycho-social counselling, we contributed to building healthier and more resilient communities.",
    key_highlights: [
      {
        topic: "Comprehensive Healthcare Services",
        description:
          "The Free Health Camp catered to the diverse healthcare needs of the community, serving a total of 203 patients. Among them, 47 cases related to psychiatry were addressed, highlighting the importance of addressing mental health issues alongside physical ailments.",
      },
      {
        topic: "Psycho-social Counselling",
        description:
          "Recognizing the significance of mental health in overall well-being, psycho-social counselling sessions were conducted to provide support and guidance to individuals facing mental health challenges. These sessions aimed to reduce stigma, increase awareness, and offer coping strategies to those in need.",
      },
      {
        topic: "Community Support",
        description:
          "In addition to healthcare services, Dirghayu Nepal distributed essential items such as tarps, mosquito nets, sanitary pads, and stationery supplies to the residents of Firfirey-7, Kutkutey. These provisions not only addressed immediate needs but also demonstrated our commitment to holistic community development.",
      },
    ],
    images: [
      "mental_health/1.jpg",
      "mental_health/2.jpg",
      "mental_health/3.jpg",
    ],
  },
  {
    _id: "4",
    title: "Remote Reach Program",
    short_des:
      "Dirghayu Nepal is committed to reaching even the most remote areas to provide essential healthcare services and raise awareness on important issues. ...",
    long_des:
      "Dirghayu Nepal is committed to reaching even the most remote areas to provide essential healthcare services and raise awareness on important issues. Our recent program in Latap and Dhuseni, two isolated regions in the Dhading district, exemplifies our dedication to serving communities in need.",
    overview:
      "In an effort to extend our reach to underserved communities, a team consisting of doctors, nurses, pharmacists, and other healthcare professionals embarked on a challenging journey to Latap and Dhuseni. These areas are located approximately seven hours off-road and require an additional two-hour walk from Dhading Besi, the nearest town. Despite the arduous journey, our team was determined to bring much-needed healthcare services to these remote villages.",
    impact:
      "The Remote Reach Program had a profound impact on the communities of Latap and Dhuseni. By bringing healthcare services directly to their doorstep and raising awareness on sanitation and mental health, we significantly improved the well-being of the residents in these remote areas.",
    key_highlights: [
      {
        topic: "Free Medical Camp",
        description:
          "Upon reaching Latap and Dhuseni, we set up a Free Medical Camp to provide healthcare services to the residents. Our team offered medical consultations, conducted health screenings, and distributed essential medications to address common health issues prevalent in the area.",
      },
      {
        topic: "Psychosocial Counselling",
        description:
          "In addition to medical services, we recognized the importance of addressing mental health concerns. Psychosocial counselling sessions were conducted to provide emotional support and guidance to individuals facing mental health challenges.",
      },
      {
        topic: "Awareness Program on Sanitation",
        description:
          "Alongside healthcare services, we conducted an awareness program on sanitation to educate the community about the importance of hygiene and sanitation practices for maintaining good health. This initiative aimed to empower residents with knowledge and practical tips to improve their living conditions.",
      },
    ],
    images: [
      "remote_reach/1.jpg",
      "remote_reach/2.jpg",
      "remote_reach/3.jpg",
      "remote_reach/4.jpg",
      "remote_reach/5.jpg",
    ],
  },
  {
    _id: "5",
    title: "Nuwakot Relief Mission",
    short_des:
      "In the face of adversity, Dirghayu Nepal remains steadfast in its commitment to serving communities in need. Our recent mission to Nuwakot, specifically Rautbesi VDC ...",
    long_des:
      "In the face of adversity, Dirghayu Nepal remains steadfast in its commitment to serving communities in need. Our recent mission to Nuwakot, specifically Rautbesi VDC, exemplifies our dedication to providing relief and healthcare services to those affected by hardship.",
    overview:
      "Dirghayu Nepal mobilized a team equipped with essential supplies and medical personnel to reach Nuwakot and conduct health camps in Rautbesi VDC. Recognizing the urgent need for assistance, we devised a strategy to provide both immediate relief and healthcare services to the affected families.",
    impact:
      "Our efforts were met with immense gratitude and appreciation from the community. We were humbled to receive blessings from the villagers, who expressed their gratitude for being the first team to reach their village during their time of need. Bringing smiles to the faces of the affected people was a deeply rewarding experience for our team, and it remains one of the happiest moments of our lives.",
    key_highlights: [
      {
        topic: "Essential Relief Material Supplies",
        description:
          "Understanding the immediate needs of the community, we developed bags containing essential food items such as noodles, biscuits, rice, lentils, beaten rice, as well as basic hygiene products including soap and sanitary pads. These supplies were crucial for addressing hunger and maintaining hygiene in challenging circumstances.",
      },
      {
        topic: "Collaboration with Authorities",
        description:
          "To ensure the success of our mission, we collaborated closely with local authorities, including the police and CDO office. Their assistance was invaluable in navigating the difficult terrain and ensuring the safety of our team as we ventured into remote areas where the need was most acute.",
      },
      {
        topic: "Health Camps and Medical Assistance",
        description:
          "Our team conducted health check-ups and provided medical assistance to individuals in need. Despite the challenging conditions, we visited approximately 50 houses in the villages, offering medical care to those who were unable to travel to the campsite.",
      },
    ],
    images: [
      "nuwakot/1.JPG",
      "nuwakot/2.JPG",
      "nuwakot/3.JPG",
      "nuwakot/4.JPG",
      "nuwakot/5.JPG",
      "nuwakot/6.JPG",
      "nuwakot/7.JPG",
      "nuwakot/8.JPG",
      "nuwakot/9.JPG",
      "nuwakot/10.JPG",
      "nuwakot/11.JPG",
      "nuwakot/12.JPG",
      "nuwakot/13.JPG",
      "nuwakot/14.JPG",
      "nuwakot/15.JPG",
      "nuwakot/16.JPG",
      "nuwakot/17.JPG",
      "nuwakot/18.JPG",
    ],
  },
];

export const BUCKET_URL = "https://pub-58cec9b6ab4044a29f93c34e8aecb2fa.r2.dev";

function Partner() {
  const navigate = useNavigate();
  const itemPerPage = 2;

  const handleViewMore = () => {
    navigate("/programs");
  };

  return (
    <div id="partner">
      <div className="partner_container">
        <div className="partner_header">
          <motion.h4
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="partner_title"
          >
            Our Programs
          </motion.h4>
          <motion.p
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="partner_desc"
          >
            These are some programs conducted by our team.
          </motion.p>
        </div>

        <div className="partner_cards home">
          {PROGRAMS?.slice(0, itemPerPage).map((partner, i) => {
            return <Card partner={partner} key={i} />;
          })}
        </div>

        {PROGRAMS.length !== 0 && (
          <motion.div
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="partner_button"
          >
            <button className="secondary-btn" onClick={handleViewMore}>
              View More
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Partner;
